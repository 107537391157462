<template lang="pug">
svg(viewBox="0 0 663.8 300", xmlns="http://www.w3.org/2000/svg")
	polygon.st0(
		points="148.907 199.312 191.296 174.656 191.296 125.344 148.907 100.567 106.397 125.344 106.397 174.656",
		style="fill: rgb(225, 202, 0)"
	)
	path.st1(
		d="M 191.296 174.656 L 148.907 199.312 L 106.518 174.656 L 106.518 125.344 L 148.907 100.688 L 191.296 125.344 L 191.296 174.656 Z M 257.004 148.543 C 257.004 121.093 264.292 95.587 276.923 73.603 C 251.053 29.757 203.806 0.364 149.636 0 C 123.887 44.453 76.032 74.332 21.255 74.332 C 21.012 74.332 20.769 74.332 20.405 74.332 C 7.53 96.559 0 122.308 0 150 C 0 175.749 6.437 199.919 17.733 220.931 C 18.704 220.931 19.676 220.81 20.648 220.81 C 77.368 220.81 126.68 252.875 151.822 299.879 C 205.628 298.785 252.51 268.907 277.773 225.061 C 264.656 202.591 257.004 176.599 257.004 148.543",
		style="fill: rgb(29, 29, 27)"
	)
	rect.st2(width="663.644", height="300", style="fill: none")
	path.st1(
		d="M 663.765 211.215 L 663.765 189.231 L 615.547 189.231 L 615.547 155.101 L 651.741 155.101 L 651.741 134.211 L 615.182 134.211 L 615.182 104.696 L 658.785 104.696 L 662.065 83.806 L 589.555 83.806 L 589.555 211.336 L 663.765 211.336 L 663.765 211.215 Z M 564.292 211.215 L 564.292 83.684 L 540 83.684 L 540.607 135.789 C 540.729 144.534 541.7 160.931 542.672 170.891 C 540.121 161.781 535.142 148.421 528.826 134.696 L 504.534 83.684 L 476.113 83.684 L 476.113 211.215 L 500.526 211.215 L 499.798 156.802 C 499.676 146.235 499.433 131.053 498.462 120.607 C 500.891 128.502 508.543 146.478 515.709 162.389 L 537.449 211.215 L 564.292 211.215 Z M 426.194 147.571 C 426.194 180.121 418.664 193.603 400.81 193.603 C 394.737 193.603 389.636 192.146 385.628 189.231 C 377.976 183.765 374.332 170.04 374.332 147.935 C 374.332 132.51 376.397 121.336 380.769 113.077 C 384.413 106.275 391.943 101.903 400.567 101.903 C 414.049 101.903 420.85 111.619 422.915 118.057 C 425.101 125.344 426.194 134.696 426.194 147.571 M 455.709 146.842 C 455.709 106.883 434.818 82.105 400.931 82.105 C 391.458 82.105 382.105 84.292 374.453 88.3 C 356.113 98.381 345.668 120.486 345.668 147.814 C 345.668 166.518 350.648 182.672 360.243 195.182 C 369.96 207.814 382.348 213.158 401.66 213.158 C 412.227 213.158 419.393 211.457 427.166 207.328 C 445.87 197.368 455.709 175.749 455.709 146.842",
		style="fill: rgb(29, 29, 27)"
	)
</template>

<script>
export default {
	name: 'BaseLogo'
};
</script>
